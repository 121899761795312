import { Controller } from "stimulus";
import consumer from "../channels/consumer"

export default class LocationController extends Controller {
  static classes = ["open", "closed"];
  static targets = ["state", "displayCount", "updatedDisplayCount", "stateIndicator"];
  displayStates = new Map();

  connect() {
    this.subscription = consumer.subscriptions.create("LocationChannel", {
      connected() {
        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received: (data) => {
        if ('location_state' in data) {
          this.handleState(data.location_state);
        }
        if ('display_state' in data) {
          this.handleDisplayState(data);
        }
      }
    });
  }

  close() {
    console.log('Closing the location');
    this.nextState = 'closed';
    this.subscription.send({
      location_state: 'closed',
    });
  }

  open() {
    console.log('Opening the location');
    this.nextState = 'open';
    this.subscription.send({
      location_state: 'open',
    });
  }

  handleState(newState) {
    switch (newState) {
      case 'open':
        this.stateTarget.innerText = 'Grønt nivå';
        this.stateIndicatorTarget.classList.add(this.openClass);
        this.stateIndicatorTarget.classList.remove(this.closedClass);
        break;
      case 'closed':
        this.stateTarget.innerText = 'Rødt nivå';
        this.stateIndicatorTarget.classList.add(this.closedClass);
        this.stateIndicatorTarget.classList.remove(this.openClass);
        break;
      default:
        this.stateTarget.innerText = 'Ukjent';
        this.stateIndicatorTarget.classList.remove(this.closedClass);
        this.stateIndicatorTarget.classList.remove(this.openClass);
        break;
    }
  }

  handleDisplayState(data) {
    console.log({ data});
    this.displayStates.set(data.display_id, data.display_state);
    this.updateDisplayStates();
  }

  updateDisplayStates() {
    this.displayCountTarget.innerText = this.displayStates.size;
    var count = 0;
    for (var value of this.displayStates.values()) {
      console.log(value);
      if (value === this.nextState) count += 1;
    }
    this.updatedDisplayCountTarget.innerText = count;
  }
}
